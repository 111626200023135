export const PRODUCT_SEARCH_GRID = 'PRODUCT_SEARCH_GRID';
export const PRODUCT_SECTION_NEW = 'PRODUCT_SECTION_NEW';
export const PRODUCT_SECTION_DISCOUNTED = 'PRODUCT_SECTION_DISCOUNTED';
export const PRODUCT_SECTION_POPULAR = 'PRODUCT_SECTION_POPULAR';
export const PRODUCT_SECTION_SUGGESTED = 'PRODUCT_SECTION_SUGGESTED';
export const PRODUCT_SECTION_CATEGORY_NEW = 'PRODUCT_SECTION_CATEGORY_NEW';
export const PRODUCT_CHAT_SUGGESTION = 'PRODUCT_CHAT_SUGGESTION';
export const PRODUCT_SEARCHBAR_SUGGESTION = 'PRODUCT_SEARCHBAR_SUGGESTION';
export const PRODUCT_SHOP_DEALS = 'PRODUCT_SHOP_DEALS';
export const PRODUCT_SHOP = 'PRODUCT_SHOP';
export const PRODUCT_SIMILAR_PRODUCTS_SECTION = 'PRODUCT_SIMILAR_PRODUCTS_SECTION';
export const PRODUCT_RECOMMENDED_BY_WISHLIST = 'PRODUCT_RECOMMENDED_BY_WISHLIST';
export const PRODUCT_RECOMMENDED_BY_RVP = 'PRODUCT_RECOMMENDED_BY_RVP';
export const PRODUCT_TOP_RECOMMENDED_BY_WISHLIST = 'PRODUCT_TOP_RECOMMENDED_BY_WISHLIST';
export const PRODUCT_TOP_RECOMMENDED_BY_RVP = 'PRODUCT_TOP_RECOMMENDED_BY_RVP';
